<template>
  <el-container>
    <el-aside ref="aside" :width="showAside ? '232px' : '16px'" style="margin-right: 16px;">
      <el-button
        :icon="showAside ? 'el-icon-arrow-right' : 'el-icon-arrow-left'"
        class="treeToggle"
        @click="handleCollapse"></el-button>
      <transition name="el-fade-in-linear">
        <vm-tree
          v-show="showAside"
          class="treeSide"
          type="useUnit"
          @node-click="onNodeClick"
          @area-click="onAreaClick"
          @node-clear="onNodeClear">
        </vm-tree>
      </transition>
    </el-aside>
    <el-main style="padding:0;width:100vh">
      <vm-table
        ref="vmTable"
        v-loading="loading"
        :filter.sync="search"
        :multi-selection="whichPage === 0"
        url="elevators/page"
        @row-click="handleRowClick"
        @selection-change="handleSelectionChange">
        <template v-if="whichPage === 0" slot="toolbar">
          <el-button v-if="tenantAuth" click="$refs.setCompany.open()" @save-success="getList(-1)">
            所属单位
          </el-button>
          <el-button v-if="tenantAuth" @click="$refs.setTenant.open()" @save-success="getList(-1)">
            所属租户
          </el-button>
          <el-button v-if="editAuth && platformNoAuth" class="addBtn" icon="el-icon-plus" @click="handleAdd">
            {{ $t("common.add") }}
          </el-button>
          <el-button class="greenBtn" :loading="exportLoading" @click="exportExcel">
            {{ $l("elevator.exportExcel", "导出") }}
          </el-button>
        </template>
        <template slot="adSearch">
          <vm-search :label="$l('maintWorkOrder.filter', '关键字检索')">
            <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" clearable></el-input>
          </vm-search>
          <vm-search :label="$l('elevator.elevatorType', '梯种')">
            <el-select
              v-model="search.productTypeId"
              :style="{ width: $l('250px', '150px') }"
              :placeholder="$t('elevator.elevatorType')"
              clearable>
              <el-option
                v-for="item in productTypeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </vm-search>
          <vm-search :label="$l('elevator.onlineStatus', '在线状态')">
            <el-select
              v-model="search.onlineStatus"
              :placeholder="$t('elevator.onlineStatus')"
              :style="{ width: $l('150px', '100px') }"
              clearable>
              <el-option
                v-for="item in onlineStatusOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </vm-search>
          <vm-search :label="$l('elevator.joinStatus', '入网状态')">
            <el-select
              v-model="search.inNetState"
              :placeholder="$t('elevator.joinStatus')"
              :style="{ width: $l('150px', '100px') }"
              clearable>
              <el-option
                v-for="item in inNetStateOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </vm-search>
          <vm-search :label="$l('elevator.joinStatus', '系统厂家')">
            <el-select
              v-model="search.systemCompany"
              placeholder="系统厂家"
              :style="{ width: $l('150px', '100px') }"
              clearable>
              <el-option
                v-for="item in systemCompanyOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </vm-search>
          <vm-search :label="$l('elevator.joinStatus', '物联网')">
            <el-select
              v-model="search.isInternet"
              placeholder="物联网"
              :style="{ width: $l('150px', '100px') }"
              clearable>
              <el-option
                v-for="item in internetList"
                :key="item.value"
                :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </vm-search>
          <vm-search v-if="$i18n.isCn" :label="$l('elevator.accessPlatform', '对接平台')">
            <el-select
              v-model="search.integrationKey"
              :placeholder="$t('elevator.accessPlatform')"
              :style="{ width: $l('160px', '150px') }"
              clearable>
              <el-option
                v-for="item in integrationList"
                :key="item.integrationKey"
                :label="item.name"
                :value="item.integrationKey"></el-option>
            </el-select>
          </vm-search>
        </template>
        <el-table-column prop="no" :label="$t('elevator.no')" width="110" align="center">
          <template v-slot="scope">
            <router-link v-if="whichPage === 1" :to="'/singleMonitor/' + scope.row.id" target="_blank">
              {{ scope.row.no }}
            </router-link>
            <router-link v-else :to="'/elevator/' + scope.row.id" target="_blank">{{ scope.row.no }}</router-link>
          </template>
        </el-table-column>
        <el-table-column
          prop="deviceId"
          show-overflow-tooltip
          label="设备代码"
          width="180"></el-table-column>
        <el-table-column prop="name" show-overflow-tooltip :label="$t('elevator.name')"></el-table-column>
        <el-table-column prop="useUnitName" :label="$t('elevator.useUnit')" show-overflow-tooltip></el-table-column>
        <el-table-column prop="maintComName" :label="$t('elevator.maintenanced')" show-overflow-tooltip></el-table-column>
        <el-table-column
          v-if="showOrgId"
          prop="orgName"
          :label="$l('elevator.belongCompany', '所属单位')"
          show-overflow-tooltip></el-table-column>
        <el-table-column v-if="showOrgId && false" prop="tenantName" label="租户" align="center"></el-table-column>
        <el-table-column
          prop="maintEmpName"
          :label="$t('elevator.maintWorker')"
          width="80"
          align="center"></el-table-column>

        <el-table-column prop="isInternet" :label="$l('workOrder.workOrderType', '物联网')" width="80" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.isInternet === 1">
              <span>有</span>
            </div>
            <div v-if="scope.row.isInternet === 0">
              <span>无</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="dtuCode" :label="$t('elevator.dtuCode')" width="110" show-overflow-tooltip></el-table-column>
        <el-table-column prop="subPlatformShortName" :label="$l('subPlatform.subPlatform', '子平台')" align="center" :width="$l('120px','100px')"></el-table-column>
        <el-table-column prop="systemCompany" :label="$l('subPlatform.subPlatform', '系统厂家')" align="center" :width="$l('120px','100px')">
          <template v-slot="scope">
            <span v-if="scope.row.systemCompany === 1">新时达</span>
            <span v-if="scope.row.systemCompany === 2">佳格</span>
            <span v-if="scope.row.systemCompany === 3">汇川</span>
            <span v-if="scope.row.systemCompany === 4">安驰</span>
            <span v-if="scope.row.systemCompany === 5">英旭</span>
            <span v-if="scope.row.systemCompany === 6">航川</span>
          </template>
        </el-table-column>
        <el-table-column v-if="$i18n.isCn" :label="$t('elevator.accessPlatform')" width="100" align="center">
          <template v-slot="scope">
            <span v-for="(item, index) in scope.row.elevatorIntegrationVos" :key="index">
              <span>{{ item.integrationKeyName + (index === scope.row.elevatorIntegrationVos.length - 1 ? "" :
                ",") }}</span>
            </span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="whichPage === 0"
          prop="inNetState"
          :label="$t('elevator.joinStatus')"
          width="80"
          align="center">
          <template v-slot="scope">
            <el-tag v-if="scope.row.inNetState" type="success">{{ $t("elevator.joined") }}</el-tag>
            <el-tag v-else type="info">{{ $t("elevator.notJoined") }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          v-else
          prop="onlineStatus"
          :label="$t('monitor.onlineStatus')"
          width="80"
          align="center">
          <template v-slot="scope">
            <el-tag v-if="scope.row.onlineStatus === 1" type="success">{{ $t("monitor.online") }}</el-tag>
            <el-tag v-else type="info">{{ $t("monitor.offline") }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          v-if="editAuth && whichPage === 0"
          fixed="right"
          align="center"
          :label="$t('common.operation')"
          :width="$l('110px', '90px')">
          <template v-slot="scope">
            <el-button type="text" @click="handleEdit(scope.row)">{{ $t("common.edit") }}</el-button>
            <el-button class="operateDelBtn" type="text" @click="handleDelete(scope.row)">
              {{ $t("common.delete")
              }}
            </el-button>
          </template>
        </el-table-column>
        <el-table-column v-if="whichPage === 1" :label="$t('common.operation')" fixed="right" width="60" align="center">
          <template v-slot="scope">
            <el-button type="text" @click="handleMonitor(scope.row)">
              {{ $t("monitor.elevatorMonitor") }}
            </el-button>
          </template>
        </el-table-column>
      </vm-table>
      <edit-dialog ref="editPage" @save-success="getList($event ? 1 : -1)"></edit-dialog>
      <vibration-data-list ref="vibrationDataPage"></vibration-data-list>
      <set-company
        ref="setCompany"
        :multi-select="multiSelect"
        action-type="elevator"
        @save-success="getList(-1)"></set-company>
      <set-tenant
        ref="setTenant"
        :multi-select="multiSelect"
        action-type="elevator"
        @save-success="getList(-1)"></set-tenant>
    </el-main>
  </el-container>
</template>
<script>
  import EditDialog from "./ElevatorEdit.vue";
  import SetTenant from "@/views/tenant/SetTenant";
  import SetCompany from "@/views/tenant/SetCompany";
  import VmTree from "../../components/VmTree.vue";
  import ElMain from "../../../node_modules/element-ui/packages/main/src/main.vue";
  import auth from "@/util/auth";
  import { Base64 } from "js-base64";

  const moduleName = "elevators";
  const moduleName_productType = "elevatorProductTypes";

  export default {
    components: {
      EditDialog,
      ElMain,
      VmTree,
      SetTenant,
      SetCompany
    },
    props: {
      whichPage: {
        type: Number,
        default: 0,
      },
    },
    data() {
      return {
        showAside: false,
        platformNoAuth: auth.getCurrentOrgId() != 0,
        tenantAuth: auth.getTenantAdmin() === "true",
        showOrgId: auth.getUsername() === "superAdmin" || auth.getTenantAdmin() === "true",
        editAuth: this.$auth(109),
        testAuth: this.$auth(136),
        checkAuth: this.$auth(137),
        search: {
          filter: "",
          systemCompany: "",
          isInternet: "",
          productTypeId: "",
          integrationKey: "",
          maintComId: "",
          quantityOrganId: "",
          createComId: "",
          installComId: "",
          remakeComId: "",
          useUnitId: "",
          onlineStatus: "",
          inNetState: "",
          hasActivatedParts: "",
          districtId: "",
          subPlatformId: "",
        },
        loading: false,
        exportLoading: false,
        multipleDisabled: false,
        reportDisabled: false,
        multiSelect: [],
        productTypeOptions: [],
        inNetStateOptions: [
          { value: "false", label: this.$t("elevator.notJoined") },
          { value: "true", label: this.$t("elevator.joined") },
        ],
        systemCompanyOptions: [
          { value: "1", label: "新时达" },
          { value: "2", label: "佳格" },
          { value: "3", label: "汇川" },
          { value: "4", label: "安驰" },
          { value: "5", label: "英旭" },
          { value: "6", label: "航川" },
        ],
        onlineStatusOptions: [
          { value: 0, label: this.$t("device.offline") },
          { value: 1, label: this.$t("device.online") },
        ],
        internetList: [
          { value: 1, label: "有" },
          { value: 0, label: "无" },
        ],
        integrationList: [],
      };
    },
    mounted() {
      // alert(auth.getUsername() === "superAdmin" || auth.getTenantAdmin());
      this.getList(1);
      this.getProductTypeOptions();
      this.getIntegrationList();
    },
    methods: {
      preview(devId) {

      },
      onNodeClick(data) {
        if (data.type === "useUnit") {
          this.search.useUnitId = data.id;
          this.search.districtId = "";
          this.getList(1);
        }
      },
      onAreaClick(data) {
        this.search.districtId = data.id;
        this.search.useUnitId = "";
        this.getList(1);
      },
      onNodeClear() {
        this.search.districtId = "";
        this.search.useUnitId = "";
        this.getList(1);
      },
      getList(pageIndex) {
        this.loading = true;
        this.$refs.vmTable.getList(pageIndex);
        this.loading = false;
      },
      link(videoUrl) {
        window.open(videoUrl);
      },
      getProductTypeOptions() {
        this.$api.getList(moduleName_productType).then(response => {
          for (let productType of response.data) {
            let item = "";
            if (this.$i18n.isCn) {
              item = { value: productType.elevatorProductTypeID, label: productType.elevatorProductTypeName };
            } else {
              item = { value: productType.elevatorProductTypeID, label: productType.elevatorProductTypeNameEn };
            }
            this.productTypeOptions.push(item);
          }
        }).catch(() => {
          this.$message.error(this.$t("common.tip.getDataError"));
        });
      },
      handleSelectionChange(val) {
        this.multiSelect = val;
        this.reportDisabled = false;
        this.multipleDisabled = this.multiSelect.length > 1;
        this.multiSelect.forEach(elevator => {
          if (elevator.elevatorType === "escalator") {
            this.reportDisabled = true;
          }
        });
      },
      handleAdd() {
        this.$refs.editPage.open(0);
      },
      handleEdit(row) {
        this.$refs.editPage.open(row.id);
      },
      handleMonitor(row) {
        window.open("#/singleMonitor/" + row.id);
      },
      handleMonitorNew(row) {
        window.open("#/singleMonitorNew/" + row.id + "/" + row.registerNo);
      },
      handleDelete(row) {
        this.$confirm(this.$t("common.delete") + " " + this.$t("elevator.no") + " " + row.no + "," + this.$t("common.isDelete") + "?", this.$t("common.tips"), {
          confirmButtonText: this.$t("common.confirm"),
          cancelButtonText: this.$t("common.cancel"),
          type: "warning",
        }).then(() => {
          this.$api.deleteById(moduleName, row.id).then(res => {
            this.getList(-1);
            this.$message.success(this.$t("common.tip.deleteSuccess"));
          }).catch((error) => {
            if (error.response) {
              this.$message.error(this.$t("common.tip.deleteError") + "," + error.response.data.message);
            }
          });
        });
      },
      handleRowClick(row, column) {
        if (column && column.label === this.$t("common.operation")) {
          return;
        }
        this.$refs.vmTable.$refs.elTable.toggleRowSelection(row);
      },
      exportExcel() {
        this.exportLoading = true;
        let params = {
          filter: this.search.filter,
          productTypeId: this.search.productTypeId,
          districtId: this.search.districtId,
          useUnitId: this.search.useUnitId,
          integrationKey: this.search.integrationKey,
          onlineStatus: this.search.onlineStatus,
          inNetState: this.search.inNetState,
          hasActivatedParts: this.search.hasActivatedParts,
        };
        this.$api
          .exportFile("elevators/export", params, 60)
          .then(res => {
            this.exportLoading = false;
            let blob = new Blob([res.data]);
            let fileName = "";
            if (this.$i18n.isCn) {
              fileName = "电梯信息.xlsx";
            } else {
              fileName = "Elevator Info.xlsx";
            }
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              navigator.msSaveBlob(blob, fileName);
            } else {
              let a = document.createElement("a");
              a.href = URL.createObjectURL(blob);
              a.download = fileName;
              a.style.display = "none";
              document.body.appendChild(a);
              a.click();
              URL.revokeObjectURL(a.href);
              document.body.removeChild(a);
            }
          })
          .catch(() => {
            this.exportLoading = false;
          });
      },
      getIntegrationList() {
        this.$http.get("integrations/list").then(({ data }) => {
          this.integrationList = data;
        });
      },
      handleCollapse() {
        this.showAside = !this.showAside;
        if (this.showAside) {
          this.$refs.aside.$el.setAttribute("style", "margin-right: 20px");
        } else {
          this.$refs.aside.$el.setAttribute("style", "margin-right: 0px");
        }
      },
    },
  };
</script>
