<template>
  <el-dialog
    :title="title"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    width="1500px"
    class="adaptationModal"
    @close="onDialogClose">
    <div v-loading="contentLoading" class="body">
      <el-form
        ref="formValidate"
        class="form"
        :label-width="$l('elevator.labelWidth', '120px')"
        :model="elevator"
        :rules="ruleValidate">
        <el-tabs v-model="activeTab" type="card" @tab-click="tabClick">
          <el-tab-pane :label="$t('elevator.baseInfo')" name="first">
            <div class="vm-separate">
              <el-form-item :label="$l('elevatorNetApply.proName', '项目名称')" prop="projectName">
                <el-input v-model.trim="elevator.projectName" :placeholder="$t('common.pleaseEnter')"></el-input>
              </el-form-item>
              <el-form-item :label="$l('elevator.whereUsed', '物联网')" prop="isInternet">
                <el-select v-model="elevator.isInternet" :placeholder="$t('common.pleaseSelect')" style="width: 100%">
                  <el-option
                    v-for="item in internetList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <div></div>
            </div>
            <div class="vm-separate">
              <el-form-item :label="$t('elevator.elevatorType')" prop="productTypeId">
                <el-select
                  v-model="elevator.productTypeId"
                  :placeholder="$t('elevator.elevatorType')"
                  style="width: 100%">
                  <el-option
                    v-for="item in productTypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('elevator.name')" prop="name">
                <el-input v-model.trim="elevator.name" :placeholder="$t('common.pleaseEnter')"></el-input>
              </el-form-item>
              <el-form-item :label="$t('elevator.model')" prop="model">
                <el-input v-model="elevator.model" :placeholder="$t('common.pleaseEnter')"></el-input>
              </el-form-item>
            </div>
            <div class="vm-separate">
              <el-form-item :label="$l('elevator.brand', '电梯品牌')" prop="brand">
                <el-input v-model="elevator.brand" :placeholder="$t('common.pleaseEnter')" clearable></el-input>
              </el-form-item>
              <el-form-item :label="$t('elevator.whereUsed')" prop="applyPlaceId">
                <el-select v-model="elevator.applyPlaceId" :placeholder="$t('elevator.whereUsed')" style="width: 100%">
                  <el-option
                    v-for="item in placeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$l('elevator.registerCode', '设备代码')" prop="deviceId">
                <el-input v-model.trim="elevator.deviceId" :placeholder="$t('common.pleaseEnter')"></el-input>
              </el-form-item>
            </div>
            <div class="vm-separate">
              <el-form-item :label="$t('elevator.exFactoryNo')" prop="factoryNo" :rules="$rule.notNull">
                <el-input v-model.trim="elevator.factoryNo" :placeholder="$t('common.pleaseEnter')"></el-input>
              </el-form-item>
              <el-form-item :label="$t('elevator.controlCabinetLeaveNo')" prop="controlCabinetLeaveNo">
                <el-input
                  v-model.trim="elevator.controlCabinetLeaveNo"
                  :placeholder="$t('common.pleaseEnter')"></el-input>
              </el-form-item>
              <el-form-item label="注册代码" prop="regNo">
                <el-input v-model.trim="elevator.regNo" :placeholder="$t('common.pleaseEnter')"></el-input>
              </el-form-item>
            </div>
            <div class="vm-separate">
              <el-form-item :label="$t('elevator.exFactoryDate')" prop="factoryDate">
                <el-date-picker
                  v-model="elevator.factoryDate"
                  type="date"
                  :placeholder="$t('common.pleaseSelect')"
                  style="width: 100%"
                  value-format="yyyy-MM-dd"></el-date-picker>
              </el-form-item>
              <el-form-item :label="$t('elevator.installationDate')" prop="installDate">
                <el-date-picker
                  v-model="elevator.installDate"
                  type="date"
                  :placeholder="$t('common.pleaseSelect')"
                  style="width: 100%"
                  value-format="yyyy-MM-dd"></el-date-picker>
              </el-form-item>
              <el-form-item :label="$t('elevator.serviceDate')" prop="useDate">
                <el-date-picker
                  v-model="elevator.useDate"
                  type="date"
                  :placeholder="$t('common.pleaseSelect')"
                  style="width: 100%"
                  value-format="yyyy-MM-dd"></el-date-picker>
              </el-form-item>
            </div>
            <div class="vm-separate">
              <el-form-item :label="$t('elevator.useStatus')">
                <el-radio-group v-model="elevator.useState">
                  <el-radio :label="1">{{ $t("elevator.useStatus_1") }}</el-radio>
                  <el-radio :label="2">{{ $t("elevator.useStatus_2") }}</el-radio>
                  <el-radio :label="3">{{ $t("elevator.useStatus_3") }}</el-radio>
                  <el-radio :label="4">{{ $t("elevator.useStatus_4") }}</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item :label="$t('elevator.joinStatus')">
                <el-switch
                  v-model="elevator.inNetState"
                  :active-text="$l('elevator.yes', '是')"
                  :inactive-text="$l('elevator.No', '否')"></el-switch>
              </el-form-item>
              <el-form-item :label="$t('elevator.manufactured')" prop="createComId">
                <el-input v-model="elevator.createComName" :placeholder="$t('common.pleaseSelect')" readonly>
                  <el-button slot="append" icon="el-icon-search" @click="$refs.selectCreateCom.open()"></el-button>
                </el-input>
                <company-select ref="selectCreateCom" @select="onSelectCreateCom"></company-select>
              </el-form-item>
            </div>
            <div class="vm-separate">
              <el-form-item :label="$t('elevator.installationEnterprise')" prop="installComId">
                <el-input
                  v-model="elevator.installComName"
                  :placeholder="$t('common.pleaseSelect')"
                  clearable
                  @clear="installComClear">
                  <el-button slot="append" icon="el-icon-search" @click="$refs.selectInstallCom.open()"></el-button>
                </el-input>
                <company-select ref="selectInstallCom" @select="onSelectInstallCom"></company-select>
              </el-form-item>
              <el-form-item :label="$t('elevator.reconstructed')" prop="remakeComId">
                <el-input
                  v-model="elevator.remakeComName"
                  :placeholder="$t('common.pleaseSelect')"
                  clearable
                  @clear="remakeComClear">
                  <el-button slot="append" icon="el-icon-search" @click="$refs.selectRemakeCom.open()"></el-button>
                </el-input>
                <company-select ref="selectRemakeCom" @select="onSelectRemakeCom"></company-select>
              </el-form-item>
              <el-form-item :label="$t('elevator.useUnit')" prop="useUnitId">
                <el-input v-model="elevator.useUnitName" :placeholder="$t('common.pleaseSelect')" readonly>
                  <el-button
                    slot="append"
                    icon="el-icon-search"
                    @click="$refs.selectUseUnitProperty.open()"></el-button>
                </el-input>
                <use-unit-select ref="selectUseUnitProperty" @select="onSelectUseUnit"></use-unit-select>
              </el-form-item>
            </div>

            <div class="vm-separate">
              <el-form-item :label="$t('elevator.maintenanced')" prop="maintComId">
                <el-input
                  v-model="elevator.maintComName"
                  :placeholder="$t('common.pleaseSelect')"
                  clearable
                  @clear="maintComClear">
                  <el-button slot="append" icon="el-icon-search" @click="$refs.selectMaintCom.open()"></el-button>
                </el-input>
                <company-select ref="selectMaintCom" @select="onSelectMaintCom"></company-select>
              </el-form-item>
              <el-form-item :label="$t('elevator.maintWorker') + '1'" prop="maintEmpId">
                <el-input
                  v-model="elevator.maintEmpName"
                  :placeholder="$t('common.pleaseSelect')"
                  clearable
                  @clear="maintEmpClear">
                  <el-button slot="append" icon="el-icon-search" @click="selectMaintEmp()"></el-button>
                </el-input>
                <user-select
                  ref="onSelectMaintEmp"
                  :maint-worker-com-id="elevator.maintComId"
                  @select="onSelectMaintEmp"></user-select>
              </el-form-item>
              <el-form-item :label="$t('elevator.maintWorker') + '2'" prop="maintEmpId1">
                <el-input
                  v-model="elevator.maintEmpName1"
                  :placeholder="$t('common.pleaseSelect')"
                  clearable
                  @clear="maintEmp1Clear">
                  <el-button slot="append" icon="el-icon-search" @click="selectMaintEmp1()"></el-button>
                </el-input>
                <user-select
                  ref="onSelectMaintEmp1"
                  :maint-worker-com-id="elevator.maintComId"
                  @select="onSelectMaintEmp1"></user-select>
              </el-form-item>
            </div>
            <div class="vm-separate">
              <el-form-item :label="$t('elevator.propertyCom')" prop="elevatorPropertyComId">
                <el-input
                  v-model="elevator.elevatorPropertyComName"
                  :placeholder="$t('common.pleaseSelect')"
                  clearable
                  @clear="propertyComClear">
                  <el-button slot="append" icon="el-icon-search" @click="$refs.selectPropertyCom.open()"></el-button>
                </el-input>
                <company-select ref="selectPropertyCom" @select="onSelectPropertyCom"></company-select>
              </el-form-item>
              <el-form-item :label="$l('elevator.whereUsed', '系统厂家')" prop="systemCompany" :rules="$rule.notNull">
                <el-select
                  v-model="elevator.systemCompany"
                  :placeholder="$t('common.pleaseSelect')"
                  style="width: 100%">
                  <el-option
                    v-for="item in systemCompanyList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$l('elevator.joinDate', '年度大保养时间')" prop="yearMaintTime">
                <el-date-picker
                  v-model="elevator.yearMaintTime"
                  type="date"
                  :placeholder="$t('common.pleaseSelect')"
                  style="width: 100%"
                  value-format="yyyy-MM-dd"></el-date-picker>
              </el-form-item>
            </div>
            <div class="vm-separate">
              <el-form-item :label="$t('elevator.joinDate')" prop="inNetDate">
                <el-date-picker
                  v-model="elevator.inNetDate"
                  type="date"
                  :placeholder="$t('common.pleaseSelect')"
                  style="width: 100%"
                  value-format="yyyy-MM-dd"></el-date-picker>
              </el-form-item>
              <div></div>
              <div></div>
            </div>
            <el-divider content-position="left">{{ $t("elevator.parameters") }}</el-divider>
            <div class="vm-separate">
              <el-form-item :label="$t('elevator.ratedSpeedOfElevator')" prop="ratedSpeed">
                <el-input
                  v-model="elevator.ratedSpeed"
                  type="number"
                  :placeholder="$t('common.pleaseEnter')"></el-input>
              </el-form-item>
              <el-form-item :label="$t('elevator.ratedLoadOfElevator')" prop="ratedLoad">
                <el-input v-model="elevator.ratedLoad" type="number" :placeholder="$t('common.pleaseEnter')"></el-input>
              </el-form-item>
              <div></div>
            </div>
            <el-divider content-position="left"></el-divider>
            <div class="vm-separate">
              <el-form-item :label="$t('useUnit.districtId')" prop="districtId">
                <vm-district-select v-model="elevator.districtId" @clear="elevator.districtId = 0"></vm-district-select>
              </el-form-item>
              <el-form-item :label="$t('elevator.address')" prop="address">
                <el-input v-model.trim="elevator.address" :placeholder="$t('common.pleaseEnter')"></el-input>
              </el-form-item>
              <!--位置码-->
              <el-form-item :label="$t('elevator.locCode')" prop="locCode">
                <el-input v-model.trim="elevator.locCode" :placeholder="$t('common.pleaseEnter')"></el-input>
              </el-form-item>
            </div>
            <div v-if="$i18n.isCn" class="vm-separate">
              <el-form-item :label="$t('elevator.longitude')" prop="longitude">
                <el-input v-model="elevator.longitude" :placeholder="$t('common.pleaseSelect')" readonly></el-input>
              </el-form-item>
              <el-form-item :label="$t('elevator.latitude')" prop="latitude">
                <el-input v-model="elevator.latitude" :placeholder="$t('common.pleaseSelect')" readonly></el-input>
              </el-form-item>
              <div>
                <el-button
                  type="primary"
                  style="width: 90%; margin: 0 10%;"
                  @click="$refs.positionEdit.open(elevator.longitude, elevator.latitude)">
                  {{ $t("elevator.modifyLocation") }}
                </el-button>
                <elevator-position-edit ref="positionEdit" @select="onSelectElevatorPosition"></elevator-position-edit>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane v-if="elevator.isInternet === 1" :label="$t('elevator.iot')" name="second">
            <div class="vm-separate">
              <el-form-item prop="subPlatformId" :label="$l('subPlatform.subPlatform', '子平台')">
                <el-select
                  v-model="elevator.subPlatformId"
                  :placeholder="$l('subPlatform.subPlatform', '子平台')"
                  style="width: 100%"
                  clearable>
                  <el-option
                    v-for="item in subPlatformList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('elevator.faultTemplate')">
                <el-input v-model="elevator.faultName" :placeholder="$t('common.pleaseSelect')" readonly>
                  <el-button slot="append" icon="el-icon-search" @click="$refs.selectFault.open()"></el-button>
                </el-input>
                <fault-select ref="selectFault" @select="onselectFault"></fault-select>
              </el-form-item>
              <el-form-item v-if="$i18n.isCn" :label="$t('elevator.accessPlatform')" prop="integrationKeys">
                <el-select
                  v-model="elevator.integrationKeys"
                  multiple
                  :placeholder="$t('elevator.accessPlatform')"
                  style="width: 100%"
                  clearable>
                  <el-option
                    v-for="item in integrationList"
                    :key="item.integrationKey"
                    :label="item.name"
                    :value="item.integrationKey"></el-option>
                </el-select>
              </el-form-item>
            </div>
            <el-divider content-position="left">{{ $t("elevator.deviceGateway") }}</el-divider>
            <div class="vm-separate">
              <el-form-item :label="$t('elevator.dtuModel')" prop="deviceModel">
                <el-input
                  v-model="elevator.deviceGateway.deviceModel"
                  :placeholder="$t('common.pleaseEnter')"></el-input>
              </el-form-item>
              <el-form-item :label="$t('elevator.dtuCode')" prop="dtuCode">
                <el-input
                  v-model.trim="elevator.dtuCode"
                  :placeholder="$t('common.pleaseEnter')"></el-input>
              </el-form-item>
              <!--              <el-form-item :label="$t('elevator.nodeCode')" prop="nodeCode">-->
              <!--                <el-input-->
              <!--                  v-model.trim="elevator.nodeCode"-->
              <!--                  :placeholder="$t('common.pleaseEnter')"></el-input>-->
              <!--              </el-form-item>-->
              <el-form-item :label="$t('elevator.simCardNo')" prop="simCode">
                <el-input
                  v-model.trim="elevator.deviceGateway.simCode"
                  :placeholder="$t('common.pleaseEnter')"></el-input>
              </el-form-item>
            </div>
            <div class="vm-separate">
              <el-form-item :label="$l('elevator.iccid', 'SIM卡ICCID')" prop="iccid">
                <el-input v-model.trim="elevator.deviceGateway.simICCID" disabled></el-input>
              </el-form-item>
              <el-form-item :label="$l('elevator.signalType', '信号类型')" prop="signalType">
                <el-input
                  v-model.trim="elevator.deviceGateway.signalType"
                  :placeholder="$t('common.pleaseEnter')"></el-input>
              </el-form-item>
              <div></div>
            </div>
            <el-divider></el-divider>
            <div class="vm-separate">
              <el-form-item :label="$t('elevator.floorDisplayTemplate')" prop="floorDisplayId">
                <el-input v-model="elevator.floorDisplayName" :placeholder="$t('common.pleaseSelect')" readonly>
                  <el-button slot="append" icon="el-icon-search" @click="$refs.selectFloorDisplay.open()"></el-button>
                </el-input>
                <floor-display-select ref="selectFloorDisplay" @select="onSelectFloorDisplay"></floor-display-select>
              </el-form-item>
              <div></div>
              <div>
                <el-button type="success" @click="batchDownLoad()">{{ $l("elevator.floorQr", "楼层二维码打包下载") }}</el-button>
                <div hidden>
                  <div v-for="(item, index) in elevator.elevatorFloors" :key="index">
                    <div :id="item.id">
                      <vue-qr
                        :text="getUrl(item.displayValue)"
                        logo-src="/static/images/step.png"
                        :logo-scale="0.3"
                        :logo-margin="5"
                        :correct-level="3"
                        :size="360"></vue-qr>
                    </div>
                  </div>
                </div>
                <canvas id="box" style="display: none;background: #FFFFFF;" width="400px" height="500px"></canvas>
              </div>
            </div>
            <el-row>
              <el-table
                :data="elevator.elevatorFloors"
                max-height="300"
                border
                stripe
                style="width: 100%">
                <el-table-column prop="value" :label="$t('elevator.actualFloor')" width="150px">
                  <template slot-scope="scope">
                    {{ scope.row.value }}
                  </template>
                </el-table-column>
                <el-table-column prop="currentFloorCode" :label="$t('elevator.currentFloorCode')" width="200px">
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.currentFloorCode"></el-input>
                  </template>
                </el-table-column>
                <el-table-column prop="displayValue" :label="$t('elevator.displayedFloor')" width="150px">
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.displayValue"></el-input>
                  </template>
                </el-table-column>
                <el-table-column prop="stopStatus" :label="$l('elevator.stopStatus', '当前楼层是否停靠')" width="200px">
                  <template slot-scope="scope">
                    <el-select v-model="scope.row.stopStatus" clearable :placeholder="$t('common.pleaseSelect')">
                      <el-option
                        v-for="item in stopStatusList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"></el-option>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('common.operation')" align="center">
                  <template slot-scope="scope">
                    <el-button type="text" @click.native.prevent="deleteRow(scope.$index)">
                      {{ $t("common.delete") }}
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
              <div style="margin-top: 10px;float: left">
                <el-button type="text" @click="handleAddFloorClick()">
                  + {{ $t("elevator.addFloorDisPlay")
                  }}
                </el-button>
              </div>
            </el-row>
            <div class="vm-separate">
            </div>
            <div class="vm-separate">
            </div>
          </el-tab-pane>
        </el-tabs>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <template>
        <elevator-copy ref="submitCopy" @submitCopy="onSubmitCopy"></elevator-copy>
        <el-button type="success" @click="$refs.submitCopy.open(elevator.id)">{{ $t("elevator.copy") }}</el-button>
      </template>
      <el-button @click="dialogVisible = false">{{ $t("common.cancel") }}</el-button>
      <el-button type="primary" :loading="submitLoading" :disabled="saveDisabled" @click="handleSubmit">
        {{ $t("common.save") }}
      </el-button>
    </span>
  </el-dialog>
</template>
<script>
  import CompanySelect from "../company/CompanySelect.vue";
  import UseUnitSelect from "../company/useUnitCom/UseUnitSelect.vue";
  import FloorDisplaySelect from "../floorDisplay/FloorDisplaySelect.vue";
  import UserSelect from "../user/UserSelect.vue";
  import FaultSelect from "../fault/FaultSelect.vue";
  import ElevatorCopy from "./ElevatorCopy.vue";
  import ElevatorPositionEdit from "./ElevatorPositionEdit.vue";
  import VueQr from "vue-qr";
  import JSZip from "jszip";
  import FileSaver from "file-saver";
  import { stopStatus, stopStatus_en, typeToArray } from "@/util/constant";
  import VmDistrictSelect from "../../components/VmDistrictSelect";

  const moduleName = "elevators";
  const moduleName_productType = "elevatorProductTypes";
  const moduleName_FloorMap = "floorDisplayMappings";

  export default {
    components: {
      VmDistrictSelect,
      ElevatorCopy,
      CompanySelect,
      UseUnitSelect,
      FloorDisplaySelect,
      UserSelect,
      FaultSelect,
      ElevatorPositionEdit,
      VueQr,
    },
    data() {
      let ruleNumber = [
        {
          type: "number",
          required: true,
          min: 1,
          message: this.$t("common.tip.notNull"),
          trigger: "change",
        },
      ];
      return {
        inspectionAuth: this.$auth(823),
        stopStatusList: typeToArray(this.$t("lang") === "en" ? stopStatus_en : stopStatus),
        dialogVisible: false,
        contentLoading: false,
        submitLoading: false,
        saveDisabled: false,
        activeTab: "first",
        productTypeList: [],
        placeList: [],
        map: null,
        searchValue: "",
        filterText: "",
        loadingTree: false,
        integrationList: [],
        subPlatformList: [],
        data: [
          {
            id: 0,
            name: "全部",
            parentID: 0,
            children: [],
          },
        ],
        defaultProps: {
          children: "children",
          label: "name",
        },
        changePart: null,
        partsTypeId: 0,
        loading1: false,
        loading2: false,
        elevatorParts: {
          id: 0,
          elevatorID: 0,
          partsID: 0,
          partsName: "",
        },
        elevatorPartsSearch: {
          elevatorId: 0,
          partsTypeId: 0,
        },
        elevatorPartsReplacementsSearch: {
          elevatorId: 0,
          partsTypeId: 0,
        },
        dataUrls: [],
        elevator: {
          id: 0,
          no: "",
          name: "",
          isInternet: 1,
          systemCompany: "",
          yearMaintTime: "",
          productTypeId: "",
          createComId: 0,
          createComName: "",
          model: "",
          address: "",
          regNo: "",
          factoryNo: "",
          controlCabinetLeaveNo: "",
          locCode: "",
          deviceId: "",
          factoryDate: "",
          installDate: "",
          applyPlaceId: "",
          useUnitId: 0,
          useUnitName: "",
          districtId: 0,
          maintEmpId: null,
          maintEmpName: "",
          replaceMaintEmpFlag: false,
          maintEmpId1: null,
          maintEmpName1: "",
          replaceMaintEmp1Flag: false,
          inNetState: false,
          useState: 1,
          videoState: 0,
          videoChannelId: "",
          videoUrl: "",
          motorRoomVideoUrl: "",
          inNetDate: "",
          useDate: "",
          quantityOrganId: 0,
          quantityOrganName: "",
          installComId: 0,
          installComName: "",
          remakeComId: "",
          remakeComName: "",
          cameraId: "",
          cameraDeviceCode: "",
          maintComId: 0,
          maintComName: "",
          elevatorPropertyComId: 0,
          elevatorPropertyComName: "",
          faultId: 0,
          faultName: "",
          dtuCode: "",
          nodeCode: "",
          iccid: "",
          integrationKey: "",
          integrationKeys: [],
          highestFloor: 0,
          ratedLoad: 0,
          ratedSpeed: "",
          rise: 0,
          angle: 0,
          width: 0,
          length: 0,
          warningParamId: 0,
          projectName: "",
          floorDisplayId: 0,
          floorDisplayName: "",
          elevatorFloors: [],
          mac: "",
          copyNum: 0,
          deviceGateway: { id: "", deviceModel: "", simCode: "", signalType: "", simICCID: "" },
          longitude: "",
          latitude: "",
          deviceName: "",
          brand: "",
          subPlatformId: "",
        },
        systemCompanyList: [
          { value: 1, label: this.$l("newMsgSend.msgPush", "新时达") },
          { value: 2, label: this.$l("newMsgSend.email", "佳格") },
          { value: 3, label: this.$l("newMsgSend.email", "汇川") },
          { value: 4, label: this.$l("newMsgSend.email", "安驰") },
          { value: 5, label: this.$l("newMsgSend.email", "英旭") },
          { value: 6, label: this.$l("newMsgSend.email", "航川") },
        ],
        internetList: [
          { value: 1, label: "有" },
          { value: 0, label: "无" },
        ],
        ruleValidate: {
          name: [
            { required: true, message: this.$t("elevator.name") + this.$t("common.tip.notNull") },
          ],
          deviceId: [
            { required: true, message: "设备代码" + this.$t("common.tip.notNull") },
          ],
          leaveNo: [
            { required: true, message: this.$t("elevator.exFactoryNo") + this.$t("common.tip.notNull") },
          ],
          useUnitId: ruleNumber,
        },
      };
    },
    computed: {
      title() {
        return (this.elevator.id === 0 ? this.$t("common.add") : this.$t("common.edit")) + " " +
          this.$t("elevator.elevator");
      },
    },
    watch: {
      filterText(val) {
        this.$refs.tree.filter(val);
      },
    },
    methods: {
      open(id) {
        this.dialogVisible = true;
        this.elevator.id = id;
        this.elevatorParts.elevatorID = id;
        this.getAll();
        this.getIntegrationList();
        this.getSubPlatformList();
        if (id > 0) {
          this.getData();
        } else {
          if (this.$i18n.isCn) {
            this.elevator.faultId = 453;
            this.elevator.faultName = "默认故障模板（内置）";
          } else {
            this.elevator.faultId = 454;
            this.elevator.faultName = "Default fault template（built-in）";
          }
        }
      },
      getData() {
        this.contentLoading = true;
        this.saveDisabled = true;
        this.$api.getById(moduleName, this.elevator.id).then(res => {
          this.elevator = Object.assign(this.elevator, res.data);
          if (this.elevator.deviceGateway === null) {
            this.elevator.deviceGateway = {
              id: "",
              deviceModel: "",
              simCode: "",
              signalType: "",
              simICCID: "",
            };
          }
          if (this.elevator.faultId === 453 && !this.$i18n.isCn) {
            this.elevator.faultId = 454;
            this.elevator.faultName = "Default fault template（built-in）";
          }
          this.contentLoading = false;
          this.saveDisabled = false;
        }).catch((error) => {
          this.contentLoading = false;
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "," + error.response.data.message);
          }
        });
      },
      saveElevator() {
        this.submitLoading = true;
        this.$api.save(moduleName, this.elevator).then(res => {
          this.submitLoading = false;
          this.dialogVisible = false;
          this.$emit("save-success", this.elevator.id === 0);
          this.$message.success(this.$t("common.tip.saveSuccess"));
        }).catch(error => {
          this.submitLoading = false;
          this.$message.error(this.$t("common.tip.saveError") + "," + error.response.data.message);
        });
      },
      handleSubmit() {
        this.$refs.formValidate.validate((valid, item) => {
          if (this.elevator.inNetState) {
            if (!this.elevator.inNetDate) {
              this.$message.error("已入网电梯入网日期不能为空");
              return;
            }
            if (!this.elevator.dtuCode) {
              this.$message.error("已入网电梯终端编号不能为空");
              return;
            }
            for (let item of this.productTypeList) {
              if (item.value === this.elevator.productTypeId) {
                if (item.label) {
                  if (item.label.indexOf("扶梯") === -1 && item.label.indexOf("人行道") === -1) {
                    if (this.elevator.elevatorFloors.length === 0) {
                      this.$message.error("已入网电梯请完善楼层信息！");
                      return;
                    } else {
                      for (let item of this.elevator.elevatorFloors) {
                        if (!item.displayValue || !item.currentFloorCode) {
                          if (!item.displayValue.trim() || !item.currentFloorCode.trim()) {
                            this.$message.error("楼层信息不能为空！");
                            return;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          if (valid) {
            this.saveElevator();
          } else {
            let itemName = "";
            if (item.leaveNo !== undefined) {
              itemName = this.$t("elevator.exFactoryNo");
            }
            if (item.useUnitId !== undefined) {
              itemName = this.$t("elevator.useUnit");
            }
            if (item.deviceId !== undefined) {
              itemName = this.$t("elevator.registerCode");
            }
            if (item.name !== undefined) {
              itemName = this.$t("elevator.name");
            }
            this.$message.error(this.$t("common.tip.submitError") + "!  " + itemName);
          }
        });
      },
      getUrl(floor) {
        let url = "https://" + window.location.host + "/?{\"ElevatorID\":" + this.elevator.id + ",\"CurrFloor\":\"" + floor + "\"}";
        console.log(url);
        return url;
      },
      //批量下载
      batchDownLoad() {
        this.dataUrls = [];
        for (const item of this.elevator.elevatorFloors) {
          this.paintCanvas(item.id, item.displayValue);
        }
        this.handleBatchDownload();
      },
      //压缩图片
      handleBatchDownload() {
        const zip = new JSZip();
        this.dataUrls.forEach(item => {
          console.log(item);
          let arrData = item.picData.split(",");
          if (this.$i18n.isCn) {
            zip.file(item.fileName, arrData[1], { base64: true });   //向zip中添加文件
          } else {
            let fileNameEn = item.fileName.replace("层", "Floor");
            zip.file(fileNameEn, arrData[1], { base64: true });
          }
        });
        //打包压缩
        zip.generateAsync({ type: "blob" }).then(content => {
          if (this.$i18n.isCn) {
            FileSaver.saveAs(content, "【" + this.elevator.name + "】楼层呼梯二维码.zip");
          } else {
            FileSaver.saveAs(content, "【" + this.elevator.name + "】Qr Code of Floor Call Elevator.zip");
          }
        });
      },
      paintCanvas(id, floor) {
        let c = document.getElementById("box");//获取canvas画布 画布大小和canvas大小一致
        let ctx = c.getContext("2d");
        ctx.clearRect(0, 0, c.width, c.height);// 清除画布
        // Canvas居中写字，参数（context对象，要写的字，字体，颜色，绘制的高度）
        function canvas_text(_paint, _text, _fontSzie, _color, _height) {
          _paint.font = _fontSzie;
          _paint.fillStyle = _color;
          _paint.textAlign = "center";
          _paint.textBaseline = "middle";
          _paint.fillText(_text, c.width / 2, _height);
        }

        canvas_text(ctx, " 微 信 扫 码 乘 梯 ", "38px bold 黑体", "#5856D6", 38);
        canvas_text(ctx, this.elevator.name, "20px bold 黑体", "black", 77);
        let div = document.getElementById(id);
        let img = div.getElementsByTagName("img")[0];
        ctx.drawImage(img, 20, 90, 360, 360);//绘制图片， 左，上，宽，高
        canvas_text(ctx, floor + " 层", "20px bold 黑体", "black", 470);
        let dataUrl = c.toDataURL();
        // 批量下载
        this.dataUrls.push({ picData: dataUrl, fileName: floor + "层.jpg" });
      },
      getAll() {
        this.$axios.all([this.getProductTypeList(), this.getPlaceList()]).then(this.$axios.spread(function(acct, perms) {
        // 两个请求现在都执行完成
        }));
      },
      getPlaceList() {
        this.$api.getList("dicts/ApplyPlace").then(response => {
          this.placeList = [];
          for (let place of response.data) {
            let item = { value: place.code, label: this.$i18n.isCn ? place.name : place.desc };
            this.placeList.push(item);
          }
        }).catch((error) => {
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "," + error.response.data.message);
          }
        });
      },
      getProductTypeList() {
        this.$api.getList(moduleName_productType).then(response => {
          this.productTypeList = [];
          for (let productType of response.data) {
            let item = "";
            if (this.$i18n.isCn) {
              item = { value: productType.elevatorProductTypeID, label: productType.elevatorProductTypeName };
            } else {
              item = { value: productType.elevatorProductTypeID, label: productType.elevatorProductTypeNameEn };
            }
            this.productTypeList.push(item);
          }
        }).catch((error) => {
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "," + error.response.data.message);
          }
        });
      },
      onSubmitCopy(copyNum) {
        if (copyNum > 0) {
          this.elevator.id = 0;
          this.elevator.copyNum = copyNum;
          this.elevator.deviceGateway.deviceModel = "";
          this.elevator.deviceGateway.simCode = "";
          this.elevator.deviceGateway.simICCID = "";
          this.elevator.videoChannelId = "";
          this.elevator.longId = 0;
          this.elevator.dtuCode = "";
          this.elevator.inNetState = false;
          this.handleSubmit();
        } else {
          this.$message.error(this.$t("common.tip.needNum"));
        }
      },
      propertyComClear() {
        this.elevator.elevatorPropertyComId = 0;
        this.elevator.elevatorPropertyComName = "";
      },
      maintComClear() {
        this.elevator.maintComId = 0;
        this.elevator.maintComName = "";
      },
      installComClear() {
        this.elevator.installComId = 0;
        this.elevator.installComName = "";
      },
      quantityOrganClear() {
        this.elevator.quantityOrganId = 0;
        this.elevator.quantityOrganName = "";
      },
      remakeComClear() {
        this.elevator.remakeComId = 0;
        this.elevator.remakeComName = "";
      },
      maintEmpClear() {
        this.elevator.maintEmpId = 0;
        this.elevator.maintEmpName = "";
      },
      maintEmp1Clear() {
        this.elevator.maintEmpId1 = 0;
        this.elevator.maintEmpName1 = "";
      },
      selectMaintEmp() {
        if (this.elevator.maintComId === 0) {
          this.$message.error(this.$t("elevator.maintComSelectTip"));
          return;
        }
        this.$refs.onSelectMaintEmp.open();
      },
      selectMaintEmp1() {
        if (this.elevator.maintComId === 0) {
          this.$message.error(this.$t("elevator.maintComSelectTip"));
          return;
        }
        this.$refs.onSelectMaintEmp1.open();
      },
      getTreeData() {
        this.loadingTree = true;
        this.$axios.get("partsTypes/tree").then((res) => {
          this.data[0].children = res.data;
          this.loadingTree = false;
        });
      },
      getList1(pageIndex) {
        this.elevatorPartsSearch.elevatorId = this.elevator.id;
        this.elevatorPartsSearch.partsTypeId = this.partsTypeId;
        this.$refs.vmTable1.getList(pageIndex);
      },
      getList2(pageIndex) {
        this.elevatorPartsReplacementsSearch.elevatorId = this.elevator.id;
        this.elevatorPartsReplacementsSearch.partsTypeId = this.partsTypeId;
        this.$refs.vmTable2.getList(pageIndex);
      },
      tree(data) {
        this.partsTypeId = data.id;
        this.refreshList(1);
      },
      tabClick(tab, event) {
        if (tab.name === "third") {
          this.partsTypeId = 0;
          this.defaultProps = "";
          this.getList1(1);
          this.getList2(1);
          this.getTreeData();
        }
      },
      deleteRow(index) {
        this.$confirm(this.$t("common.confirmTip") + "?", this.$t("common.tips"), {
          confirmButtonText: this.$t("common.confirm"),
          cancelButtonText: this.$t("common.cancel"),
          type: "warning",
        }).then(() => {
          let rows = this.elevator.elevatorFloors;
          rows.splice(index, 1);
          for (let i = index; i < this.elevator.elevatorFloors.length; i++) {
            this.elevator.elevatorFloors[i].value = i + 1;
          }
        });
      },
      handleAddFloorClick() {
        let elevatorFloor = {
          value: this.elevator.elevatorFloors.length + 1,
          displayValue: "",
          currentFloorCode: "",
          faultFloorCode: "",
          stopStatus: 1,
        };
        this.elevator.elevatorFloors.push(elevatorFloor);
      },
      getDefaultFault(companyName) {
        console.log(companyName);
        let params = {
          "companyName": companyName,
        };
        this.$api.getData("faults/defaultFault", params).then(res => {
          if (res.data) {
            this.elevator.faultId = res.data.id;
            this.elevator.faultName = res.data.name;
          }
        }).catch((err) => {
          this.$message.error(err);
        });
      },
      onSelectUseUnit(row) {
        this.elevator.useUnitId = row.id;
        this.elevator.useUnitName = row.name;
      },
      onSelectCreateCom(row) {
        this.elevator.createComId = row.id;
        this.elevator.createComName = row.name;
        // 选择制造企业之后查找默认 故障模板
        this.getDefaultFault(this.elevator.createComName);
      },
      onSelectMaintCom(row) {
        this.elevator.maintComId = row.id;
        this.elevator.maintComName = row.name;
        this.elevator.maintEmpId = null;
        this.elevator.maintEmpName = "";
        this.elevator.maintEmpId1 = null;
        this.elevator.maintEmpName1 = "";
      },
      onSelectPropertyCom(row) {
        this.elevator.elevatorPropertyComId = row.id;
        this.elevator.elevatorPropertyComName = row.name;
      },
      onSelectInstallCom(row) {
        this.elevator.installComId = row.id;
        this.elevator.installComName = row.name;
      },
      onSelectRemakeCom(row) {
        this.elevator.remakeComId = row.id;
        this.elevator.remakeComName = row.name;
      },
      onSelectQuantityOrgan(row) {
        this.elevator.quantityOrganId = row.id;
        this.elevator.quantityOrganName = row.name;
      },
      onSelectMaintEmp(row) {
        if (this.elevator.maintEmpId1 === row.id) {
          this.$message.error("不能选择同一个维保工!");
          return;
        }
        if (this.elevator.maintEmpId > 0 && row.id !== this.elevator.maintEmpId) {
          let params = {
            "maintEmpId": this.elevator.maintEmpId,
            "elevatorId": this.elevator.id,
          };
          this.$api.getData("elevators/maintEmpJob", params).then(res => {
            if (res.data.result) {
              this.$confirm("是否把原电梯维保工【" + this.elevator.maintEmpName + "】维保的电梯全部替换为当前维保工【" + row.name + "】？", this.$t("common.tips"), {
                confirmButtonText: this.$t("common.yes"),
                cancelButtonText: this.$t("common.no"),
                type: "warning",
              }).then((res) => {
                this.elevator.maintEmpId = row.id;
                this.elevator.maintEmpName = row.name;
                this.elevator.replaceMaintEmpFlag = true;
              }).catch(() => {
                this.elevator.maintEmpId = row.id;
                this.elevator.maintEmpName = row.name;
                this.elevator.replaceMaintEmpFlag = false;
              });
            } else {
              this.elevator.maintEmpId = row.id;
              this.elevator.maintEmpName = row.name;
            }
          });
        } else {
          this.elevator.maintEmpId = row.id;
          this.elevator.maintEmpName = row.name;
        }
      },
      onSelectMaintEmp1(row) {
        if (this.elevator.maintEmpId === row.id) {
          this.$message.error("不能选择同一个维保工!");
          return;
        }
        if (this.elevator.maintEmpId1 > 0 && row.id !== this.elevator.maintEmpId1) {
          let params = {
            "maintEmpId": this.elevator.maintEmpId1,
            "elevatorId": this.elevator.id,
          };
          this.$api.getData("elevators/maintEmpJob", params).then(res => {
            if (res.data.result) {
              this.$confirm("是否把原电梯维保工【" + this.elevator.maintEmpName1 + "】维保的电梯全部替换为当前维保工【" + row.name + "】？", this.$t("common.tips"), {
                confirmButtonText: this.$t("common.yes"),
                cancelButtonText: this.$t("common.no"),
                type: "warning",
              }).then((res) => {
                this.elevator.maintEmpId1 = row.id;
                this.elevator.maintEmpName1 = row.name;
                this.elevator.replaceMaintEmp1Flag = true;
              }).catch(() => {
                this.elevator.maintEmpId1 = row.id;
                this.elevator.maintEmpName1 = row.name;
                this.elevator.replaceMaintEmp1Flag = false;
              });
            } else {
              this.elevator.maintEmpId1 = row.id;
              this.elevator.maintEmpName1 = row.name;
            }
          });
        } else {
          this.elevator.maintEmpId1 = row.id;
          this.elevator.maintEmpName1 = row.name;
        }
      },
      onselectFault(row) {
        this.elevator.faultId = row.id;
        this.elevator.faultName = row.desc;
      },
      onSelectFloorDisplay(row) {
        this.elevator.floorDisplayId = row.id;
        this.elevator.floorDisplayName = row.name;
        this.getFloorDisplayMapping();
      },
      getFloorDisplayMapping() {
        this.$api.getById(moduleName_FloorMap, this.elevator.floorDisplayId).then(res => {
          this.elevator.elevatorFloors = res.data;
        }).catch((error) => {
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "," + error.response.data.message);
          }
        });
      },
      onSelectElevatorPosition(obj) {
        this.elevator.longitude = obj.longitude;
        this.elevator.latitude = obj.latitude;
      },
      refreshList(pageIndex) {
        // 两个请求现在都执行完成
        this.$axios.all([this.getList1(pageIndex), this.getList2(1)]).then(this.$axios.spread(function(acct, perms) {
        }));
      },
      onDialogClose() {
        this.saveDisabled = false;
        this.map = null;
        this.contentLoading = false;
        // this.$refs.formValidate.resetFields();
        this.$copy(this.$data, this.$options.data.call(this), "ruleValidate");
      },
      getIntegrationList() {
        this.$http.get("integrations/list").then(({ data }) => {
          this.integrationList = data;
        });
      },
      getSubPlatformList() {
        this.$http.get("developers/list").then(res => {
          this.subPlatformList = res.data;
        });
      },
    },
  };
</script>

<style lang="scss" scoped></style>
